import { type Table } from '@tanstack/react-table';
import { IconButton } from '#components/Input/IconButton';
import { ChevronRight } from '#icons/ChevronRight';
import { ChevronLeft } from '#icons/ChevronLeft';
import { PageNumbers } from './PageNumbers';
import { ResultsPerPage } from './ResultsPerPage';
import { PaginationButtonWrapper, PaginationWrapper } from './styles';

type PaginationProps<T> = {
  table: Table<T>;
};

export const Pagination = <T,>({ table }: PaginationProps<T>) => {
  const pageCount = table.getPageCount();
  const currentPage = table.getState().pagination.pageIndex;
  const pageSize = table.getState().pagination.pageSize;
  const totalRows = table.getRowCount();
  const currentRows = table.getRowModel().rows.length;
  const startRow = totalRows === 0 ? 0 : currentPage * pageSize + 1;
  const endRow = Math.min(totalRows, currentPage * pageSize + currentRows);

  return (
    <PaginationWrapper>
      <div>
        Showing row {startRow}-{endRow} of {totalRows.toLocaleString()}
      </div>
      <PaginationButtonWrapper>
        <IconButton
          variant="secondary"
          aria-label="Previous page"
          onClick={() => {
            table.previousPage();
          }}
          disabled={!table.getCanPreviousPage()}
        >
          <ChevronLeft />
        </IconButton>
        <PageNumbers
          currentPage={currentPage}
          pageCount={pageCount}
          onClick={(index) => {
            table.setPageIndex(index);
          }}
        />
        <IconButton
          variant="secondary"
          aria-label="Next page"
          onClick={() => {
            table.nextPage();
          }}
          disabled={!table.getCanNextPage()}
        >
          <ChevronRight />
        </IconButton>
      </PaginationButtonWrapper>
      <ResultsPerPage
        totalRows={totalRows}
        pageSize={pageSize}
        onChange={(newPageSize) => {
          table.setPageSize(newPageSize);
        }}
      >
        Rows per page
      </ResultsPerPage>
      {/* <GoToPage table={table} pageCount={pageCount} currentPage={currentPage} /> */}
    </PaginationWrapper>
  );
};
