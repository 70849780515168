import { type PropsWithChildren } from 'react';
import { DropdownV2, SimpleSelectList } from '#components/Input/DropdownV2';
import { ResultsPerPageWrapper } from './styles';

type ResultsPerPageProps = {
  totalRows: number;
  pageSize: number;
  onChange: (newPageSize: number) => void;
};

function generateResultsPerPageOptions(totalRows: number): number[] {
  let options: number[] = [];

  if (totalRows < 5) {
    options = [totalRows];
  } else {
    const maxOption = Math.floor(totalRows / 2) || totalRows;

    if (maxOption <= 5) {
      options = [maxOption];
    } else {
      const numOptions = 5; // desired number of options
      const step = (maxOption - 5) / (numOptions - 1);

      for (let i = 0; i < numOptions; i++) {
        const opt = 5 + i * step;
        const roundedOpt = Math.round(opt / 10) * 10;

        if (!options.includes(roundedOpt) && roundedOpt <= maxOption) {
          options.push(roundedOpt);
        }
      }
      if (options[options.length - 1] !== maxOption) {
        options.push(maxOption);
      }
    }
  }

  return options;
}

export const ResultsPerPage = ({
  children,
  totalRows,
  pageSize,
  onChange,
}: PropsWithChildren<ResultsPerPageProps>) => {
  const options = generateResultsPerPageOptions(totalRows);

  return (
    <ResultsPerPageWrapper>
      {children}
      <DropdownV2
        onChange={(values: string[]) => {
          onChange(Number(values[0]));
        }}
        items={options.map((opt) => ({
          children: [],
          label: opt.toString(),
          type: 'value',
          value: opt.toString(),
        }))}
        placeholder={pageSize.toString()}
        selectedValues={[]}
      >
        <SimpleSelectList />
      </DropdownV2>
    </ResultsPerPageWrapper>
  );
};
