import styled from '@emotion/styled';
import { Button } from '#components/Input/Button';
import { colors, spacing, typography } from '#themes/stena-recycling';

export const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* flex-direction: row; */
  gap: ${spacing.small};
`;

export const PaginationButtonWrapper = styled.div`
  display: flex;
  gap: ${spacing.xxsmall};
`;

export const PageInfoWrapper = styled.div`
  display: flex;
  gap: ${spacing.xsmall};
  align-items: center;
`;

const activeStyles = `
    background-color: ${colors.primary.blue};
    color: ${colors.base.white};

    &:hover:not(:disabled) {
      background-color: ${colors.primary.blueShade1};
      color: ${colors.base.white};
    }
  `;

export const PageNumberButton = styled(Button)<{ isActive: boolean }>`
  min-width: 34px;

  &:hover:not(:disabled) {
    border-color: ${colors.primary.blueShade1};
  }

  ${({ isActive }) => (isActive ? activeStyles : '')}
`;

export const ResultsPerPageWrapper = styled.div`
  display: flex;
  gap: ${spacing.small};
  align-items: center;
`;

export const StyledEllipsis = styled.div`
  width: ${spacing.xsmall};
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 12px;
  color: ${colors.monochrome.grey50};
  font-size: ${typography.heading.desktop.medium.fontSize};
  user-select: none;
`;
