import { type SortDirection } from '@tanstack/react-table';
import { ArrowDown } from '#icons/ArrowDown';
import { ArrowUp } from '#icons/ArrowUp';
import { SortIconWrapper, StyledTableHeaderSorting } from './style';

type TableHeaderSortingProps = {
  children: React.ReactNode;
  isSorted: boolean | SortDirection;
  isSortable: boolean;
  onClick?: (event: unknown) => void;
};

export const TableHeaderSorting = ({
  children,
  isSorted,
  isSortable,
  onClick,
  ...rest
}: TableHeaderSortingProps) => (
  <StyledTableHeaderSorting isSortable={isSortable} onClick={onClick} {...rest}>
    {children}
    <SortIconWrapper>
      {{
        asc: <ArrowUp />,
        desc: <ArrowDown />,
      }[isSorted.toString()] ?? null}
    </SortIconWrapper>
  </StyledTableHeaderSorting>
);
