import { type Table } from '@tanstack/react-table';
import { useRef, useState } from 'react';
import {
  flip,
  useFloating,
  useInteractions,
  useClick,
  useDismiss,
  autoUpdate,
  shift,
} from '@floating-ui/react';
import { Button } from '../../../..//Input/Button';

import { Chevron } from '../../../..//Icons';
import { Checkbox } from '../../../../Input/Checkbox';
import { ColumnFilterWrapper, ColumnItem, FloatingContainer } from './styles';

type ColumnFilterProps<T> = {
  table: Table<T>;
};

export const ColumnFilter = <T,>({ table }: ColumnFilterProps<T>) => {
  const boxRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context),
  ]);

  return (
    <ColumnFilterWrapper ref={boxRef}>
      <Button
        id={`select-columns-button`}
        variant="text-primary"
        ref={refs.setReference}
        {...getReferenceProps()}
        iconPlacement="after"
        icon={<Chevron rotation={isOpen ? 180 : 0} />}
      >
        Select columns to display
      </Button>
      {isOpen && (
        <FloatingContainer
          ref={refs.setFloating}
          {...getFloatingProps()}
          style={floatingStyles}
          shadow
          p="none"
        >
          <ColumnItem key="select-all" onClick={table.getToggleAllColumnsVisibilityHandler()}>
            <Checkbox label="Select all" checked={table.getIsAllColumnsVisible()} value="all" />
          </ColumnItem>
          {table.getAllLeafColumns().map((column) => (
            <ColumnItem key={column.id} onClick={column.getToggleVisibilityHandler()}>
              <Checkbox
                label={column.columnDef.header?.valueOf().toString() ?? column.id}
                checked={column.getIsVisible()}
                value={column.id}
              />
            </ColumnItem>
          ))}
        </FloatingContainer>
      )}
    </ColumnFilterWrapper>
  );
};
