import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shouldUseNewPickerForSource } from '../../../../../../../LocationPicker/utils';
import { DropdownContexts } from '../../../../../../context/DropdownContext';
import { Checkbox } from '../../../../../../../Checkbox';
import { colors } from '../../../../../../../../../themes';
import { Chevron } from '../../../../../../../../Icons';
import { Label } from '../../../../../../../../Feedback/Label';
import { useInactiveState } from '../../../../../../../LocationPicker/hooks/useLocationPicker/useInactiveState';
import { type LocationListItemsType } from '../../types';
import { IconButton } from '../../../../../../../IconButton';
import {
  ChevronWrapper,
  GroupedListWrapper,
  IconWrapper,
  StyledCheckboxWrapper,
  StyledLabelWrapper,
} from './style';
import { DisplayLocation } from './DisplayLocation';

export const LocationListItem = ({
  item,
  children,
  nestedIndex = 0,
}: {
  item: LocationListItemsType;
  children?: React.ReactNode;
  nestedIndex?: number;
}) => {
  const { isChildOpen, toggleOpenChild, toggleOpen } = useContext(DropdownContexts.useDropdown);
  const { multiSelect } = useContext(DropdownContexts.props);
  const { toggleItem, isItemSelected } = useContext(DropdownContexts.useValues);
  const { searchActive } = useContext(DropdownContexts.useSearch);
  const { hideInactiveLocations } = useInactiveState();
  const { t } = useTranslation();

  const isFNO = shouldUseNewPickerForSource(item.meta?.source);

  const itemNotSelectable = hideInactiveLocations && !item.meta?.isActive;
  const itemIsInactive = !item.meta?.isActive;

  const hasActiveChildren = item.children.some(
    (child) => child.meta?.isActive || child.meta?.activeChildren,
  );
  const shouldToggleChildren =
    hideInactiveLocations && !hasActiveChildren ? false : item.children.length > 0;

  const checked = useMemo(
    () => isItemSelected(item, hideInactiveLocations),
    [isItemSelected, item, hideInactiveLocations],
  );

  const semiChecked = checked === 'partial';
  const isOpen = useMemo(() => isChildOpen(item.value), [isChildOpen, item.value]);

  const showInactiveLabel = !shouldUseNewPickerForSource(item.meta?.source);

  const handleChevronClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      toggleOpenChild(item.value);
    },
    [toggleOpenChild, item.value],
  );

  const isSingleAndUnselectable = !multiSelect && item.meta?.isUnselectable;

  const handleGroupClick = useCallback(
    (openChildren: boolean) => {
      if (isSingleAndUnselectable) {
        return;
      }

      if (shouldToggleChildren) {
        toggleItem(item);

        // Open children on selection for multi select
        if (!isOpen && !checked && multiSelect && openChildren) {
          toggleOpenChild(item.value);
        }
      } else {
        toggleItem(item);
      }

      // Close dropdown on selection for single select
      if (checked !== true && !multiSelect && !itemNotSelectable) toggleOpen();
    },
    [
      isSingleAndUnselectable,
      shouldToggleChildren,
      toggleItem,
      item,
      isOpen,
      checked,
      multiSelect,
      toggleOpenChild,
      itemNotSelectable,
      toggleOpen,
    ],
  );

  const showBold = useMemo(() => {
    if (isFNO) {
      return item.type === 'group' || !!item.meta?.isSamlingsMotpart;
    }

    return item.type === 'group' || nestedIndex === 0 || !!item.meta?.isSamlingsMotpart;
  }, [item, nestedIndex, isFNO]);

  const childOpen = useMemo(() => isOpen || searchActive, [isOpen, searchActive]);

  return (
    <GroupedListWrapper
      onClick={() => {
        handleGroupClick(!multiSelect);
      }}
      nestedIndex={nestedIndex}
      data-is-selected={checked}
      inactive={itemNotSelectable || !!isSingleAndUnselectable}
      showAsSelected={!multiSelect && checked === true}
    >
      {multiSelect && (
        <StyledCheckboxWrapper>
          <Checkbox
            onChange={() => {
              handleGroupClick(false);
            }}
            semiChecked={semiChecked}
            checked={semiChecked ? false : !!checked}
            color={colors.primary.grey}
          />
        </StyledCheckboxWrapper>
      )}

      <DisplayLocation
        showBold={showBold}
        label={item.label}
        inactive={itemNotSelectable && item.type !== 'group' && !isSingleAndUnselectable}
      >
        {children}
      </DisplayLocation>

      {itemIsInactive && !shouldToggleChildren && showInactiveLabel && (
        <StyledLabelWrapper>
          <Label variant="neutral">{t('components.locationpicker.location.inactive')}</Label>
        </StyledLabelWrapper>
      )}

      {shouldToggleChildren && (
        <IconWrapper>
          {itemIsInactive && showInactiveLabel && (
            <div>
              <Label variant="neutral">{t('components.locationpicker.location.inactive')}</Label>
            </div>
          )}

          <ChevronWrapper onClick={handleChevronClick}>
            <IconButton
              variant="secondary"
              onClick={() => {
                toggleOpenChild(item.value);
              }}
            >
              <Chevron height="24px" width="24px" rotation={childOpen ? 180 : 0} />
            </IconButton>
          </ChevronWrapper>
        </IconWrapper>
      )}
    </GroupedListWrapper>
  );
};
