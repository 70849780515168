import {
  type ColumnDef,
  type ColumnFiltersState,
  type ExpandedState,
  type RowData,
  type TableMeta,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Fragment, type ReactNode, useState } from 'react';
import { IconButton } from '#components/Input/IconButton';
import { Chevron } from '#icons/Chevron';
import { SkeletonLoader } from '#components/Feedback/SkeletonLoader';
import {
  StyledTable,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
  TableWrapper,
} from './style';
import { Filter } from './subs/Filter';
import { Pagination } from './subs/Pagination';
import { ColumnFilter } from './subs/ColumnFilter';
import { type ExpandableData } from './types';
import { TableHeaderSorting } from './subs/TableHeaderSorting';
import { Tooltip } from './subs/Tooltip/Tooltip';

declare module '@tanstack/react-table' {
  //allows us to define custom properties for our columns
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions, @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    filterVariant?: 'text' | 'range' | 'select';
    tooltip?: ReactNode;
  }
}

export type TableV2Props<TData> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<TData, any>[];
  data: TData[];
  allowFiltering?: boolean;
  pagination?: boolean;
  columnFiltering?: boolean;
  expandable?: boolean;
  emptyState?: React.ReactNode;
  loading?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta?: TableMeta<any>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const expandColumn = <TData,>(): ColumnDef<TData, any> => ({
  id: 'select',
  cell: ({ row }) => (
    <IconButton
      variant="secondary"
      onClick={() => {
        row.toggleExpanded();
      }}
    >
      {row.getIsExpanded() ? <Chevron rotation={180} /> : <Chevron />}
    </IconButton>
  ),
});

const getDefaultColumns = <TData,>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultColumns: ColumnDef<TData, any>[],
  expandable: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): ColumnDef<TData, any>[] => {
  if (!expandable) {
    return defaultColumns;
  }

  return [...defaultColumns, expandColumn()];
};

export const TableV2 = <TData,>({
  columns: defaultColumns,
  data,
  allowFiltering,
  pagination,
  expandable,
  columnFiltering,
  emptyState,
  loading,
  meta,
}: TableV2Props<TData>) => {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [columns] = useState<typeof defaultColumns>(
    getDefaultColumns(defaultColumns, !!expandable),
  );

  const [columnVisibility, setColumnVisibility] = useState({});

  const table = useReactTable({
    data,
    columns,
    filterFns: {},
    state: {
      columnFilters,
      columnVisibility,
      expanded,
    },
    onExpandedChange: setExpanded,
    getExpandedRowModel: expandable ? getExpandedRowModel() : undefined,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(), //client side filtering
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: pagination ? getPaginationRowModel() : undefined,
    meta,
  });

  const rowModel = table.getRowModel();

  const hasRows = rowModel.rows.length > 0;

  return (
    <TableWrapper>
      {columnFiltering && <ColumnFilter table={table} />}
      <StyledTable>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <TableHeaderRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHeaderCell key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <>
                        <TableHeaderSorting
                          isSorted={header.column.getIsSorted()}
                          isSortable={header.column.getCanSort()}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          <Tooltip>{header.column.columnDef.meta?.tooltip}</Tooltip>
                        </TableHeaderSorting>
                        {allowFiltering && header.column.getCanFilter() && (
                          <div>
                            <Filter column={header.column} />
                          </div>
                        )}
                      </>
                    )}
                  </TableHeaderCell>
                ))}
              </TableHeaderRow>
            );
          })}
        </TableHeader>
        <TableBody>
          {loading &&
            Array.from({ length: 3 }).map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableRow key={index}>
                <TableCell colSpan={columns.length}>
                  <SkeletonLoader />
                </TableCell>
              </TableRow>
            ))}
          {rowModel.rows.map((row) => (
            <Fragment key={row.id}>
              <TableRow key={row.id} data-testid={`table-row`}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <TableCell key={cell.id} data-testid={`table-cell`}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  );
                })}
              </TableRow>
              {row.getIsExpanded() && (
                <TableRow key={`${row.id}-expanded`}>
                  <TableCell colSpan={row.getVisibleCells().length}>
                    {flexRender((row.original as ExpandableData).expandedContent, {})}
                  </TableCell>
                </TableRow>
              )}
            </Fragment>
          ))}
        </TableBody>
      </StyledTable>
      {pagination && <Pagination table={table} />}
      {!hasRows && !loading && emptyState}
    </TableWrapper>
  );
};
